import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import classnames from "classnames"

import AppCta from "./appCta"
import Logo from "./logo"
import { isAuthenticated } from "../utils/auth"

class Header extends React.Component {
  state = { isScrollOnTop: true, isMenuOpen: false }

  componentDidMount() {
    document.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll = e => {
    const { isScrollOnTop } = this.state
    if (window.scrollY > 0 && isScrollOnTop) {
      this.setState({ isScrollOnTop: false })
    } else if (window.scrollY === 0 && !isScrollOnTop) {
      this.setState({ isScrollOnTop: true })
    }
  }

  toggleMenu = () => {
    this.setState(p => ({ isMenuOpen: !p.isMenuOpen }))
  }

  get css() {
    const { isIndex } = this.props
    const { isScrollOnTop, isMenuOpen } = this.state

    return classnames("header-reactive", isIndex && isScrollOnTop && "transparent", isMenuOpen && "open")
  }

  render() {
    const { isMenuOpen } = this.state
    return (
      <nav className={this.css}>
        <div className="navLinksWrap">
          <div className="header-container">
            <div className="header-group">
              <Logo/>
              <Link to="/products" className="link color-white f-18 medium">
                Gear
              </Link>
              <Link to="/#pricing" className="link color-white f-18 medium">
                Pricing
              </Link>
              <Link to="/faq" className="link color-white f-18 medium">
                FAQ
              </Link>
              <a href="https://blog.mixanalog.com" className="link color-white f-18 medium">
                Blog
              </a>
            </div>
            <div className="header-group">
              <AppCta small/>
              {false ? (
                <Link to="/logout" className="link color-white f-18 medium">
                  Log out
                </Link>
              ) : (
                <a href="https://app.mixanalog.com" className="link color-white medium"
                   style={{ fontSize: "0.9em", marginRight: 0 }}>
                  Log In
                </a>
              )}
            </div>
          </div>
        </div>
        <button className={`burger${isMenuOpen ? " open" : ""}`} onClick={this.toggleMenu}/>
      </nav>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  isIndex: PropTypes.bool,
}

Header.defaultProps = {
  siteTitle: ``,
  isIndex: false,
}

export default Header
