import React from "react"

export const SocialLinks1 = ({ color = "main" }) => (
  <>
    <a href="https://facebook.com/mixanalogcloud" target="_blank" rel="noopener noreferrer" className="mb-15 link color-heading d-flex align-items-center">
      <i className={`fab fa-facebook-square f-18 w-30 flex-shrink-0 color-${color}`} />
      <div className={`color-${color}`}>Facebook</div>
    </a>
    <a href="https://instagram.com/mixanalogcloud" target="_blank" rel="noopener noreferrer" className="mb-15 link color-heading d-flex align-items-center">
      <i className={`fab fa-instagram f-18 w-30 flex-shrink-0 color-${color}`} />
      <div className={`color-${color}`}>Instagram</div>
    </a>
    <a href="https://twitter.com/mixanalogcloud" target="_blank" rel="noopener noreferrer" className="mb-15 link color-heading d-flex align-items-center">
      <i className={`fab fa-twitter f-18 w-30 flex-shrink-0 color-${color}`} />
      <div className={`color-${color}`}>Twitter</div>
    </a>
  </>
)

export const SocialLinks2 = ({ color = "main" }) => (
  <>
    <a href="https://youtube.com/c/mixanalogcloud" target="_blank" rel="noopener noreferrer" className="mb-15 link color-heading d-flex align-items-center">
      <i className={`fab fa-youtube f-18 w-30 flex-shrink-0 color-${color}`} />
      <div className={`color-${color}`}>YouTube</div>
    </a>
    {/*<a href="#" target="_blank" rel="noopener noreferrer" className="mb-15 link color-heading d-flex align-items-center">
      <i className={`fab fa-product-hunt f-18 w-30 flex-shrink-0 color-${color}`} />
      <div className={`color-${color}`}>Product Hunt</div>
    </a>*/}
    <a href="https://github.com/distopik" rel="noopener noreferrer" target="_blank" className="mb-15 link color-heading d-flex align-items-center">
      <i className={`fab fa-github f-18 w-30 flex-shrink-0 color-${color}`} />
      <div className={`color-${color}`}>Github</div>
    </a>
  </>
)

const SocialMediaLinks = ({ color = "main" }) => (
  <>
    <div className="col-sm-5 col-6" data-aos-duration="600" data-aos="fade-down">
      <SocialLinks1 color={color} />
    </div>
    <div className="col-sm-5 col-6" data-aos-duration="600" data-aos="fade-down">
      <SocialLinks2 color={color} />
    </div>
  </>
)

export default SocialMediaLinks
