import React from "react"
import classnames from "classnames"

const PlayVideoButton = props => (
  <a href="https://app.mixanalog.com" className={classnames("btn youtube-button", props.small && "small", props.centered && "centered-button")}>
    {props.title || "Try it for free"}
  </a>
)

export default PlayVideoButton
