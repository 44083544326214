import React from "react"
import { Link } from "gatsby"

const Logo = () => (
  <Link to={"/"} className="headerLogo">
    <img src={require("../i/logo-3-opt.svg")} alt={"logo"} />
  </Link>
)

export default Logo
