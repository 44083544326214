import React from "react"
import { Link } from "gatsby"

import { SocialLinks1, SocialLinks2 } from "./socialmedia"

const Footer = () => (
  <footer className="footer_9 bg-dark pt-90 pb-75 color-white text-center text-sm-left">
    <div className="container px-xl-0">
      <div className="row justify-content-center">
        <div className="mb-50 mb-lg-0 col-lg-4" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
          <div className="mb-30 f-22 title">15,000 users registered since January 2017</div>
          <div className="mb-35 text-adaptive">We've created the product that will help you succeed in audio production. Keep in touch!</div>
          <form name="Mailing List" method="POST" action="/mailing-list/thank-you" className="mb-100 row no-gutters" data-netlify={"true"}>
            <input type={"hidden"} name={"form-name"} value={"Mailing List"} />
            <div className="col-lg-7 col-md-5 col-7">
              <input
                type="email"
                name="email"
                placeholder="Your email"
                className="w-full input sm border-transparent-white focus-white color-white placeholder-transparent-white"
                required
              />
            </div>
            <div className="col-lg-5 col-md-3 col-5">
              <div className="ml-10">
                <button className="btn sm action-1 w-full f-16">Subscribe</button>
              </div>
            </div>
          </form>
          <div className="row no-gutters justify-content-center justify-content-sm-start">
            <div className="col-lg-auto col-sm-4 col-auto">
              <Link to="/terms-of-service" className="mr-10 link color-white">
                Terms of Service
              </Link>
            </div>
            <div className="col-lg-auto col-sm-4 col-auto">
              <Link to="/privacy" className="ml-10 link color-white">
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-sm-4 col-6 links" data-aos-duration="600" data-aos="fade-down" data-aos-delay="300">
          <div className="mb-25 f-18 medium title">
            <b>About</b>
          </div>
          <div>
            <Link to="/#explainer" className="mb-20 link color-white">
              Features
            </Link>
          </div>
          <div>
            <Link to="/products" className="mb-20 link color-white">
              Gear
            </Link>
          </div>
          <div>
            <Link to="/team" className="mb-20 link color-white">
              Team
            </Link>
          </div>
          <div>
            <Link to="/team#story" className="mb-20 link color-white">
              Our Story
            </Link>
          </div>
          <div>
            <Link to="/technology" className="mb-20 link color-white">
              Digital Control
            </Link>
          </div>
        </div>
        <div className="col-lg-2 col-sm-4 col-6 links" data-aos-duration="600" data-aos="fade-down" data-aos-delay="600">
          <div className="mb-25 f-18 medium title">
            <b>Resources</b>
          </div>
          <div>
            <Link to="/faq" className="mb-20 link color-white">
              FAQ
            </Link>
          </div>
          <div>
            <Link to="/plugin" className="mb-20 link color-white">
              Plug-in
            </Link>
          </div>
          <div>
            <Link to="/webinar" className="mb-20 link color-white">
              Webinar
            </Link>
          </div>
          <div>
            <a href="https://speedtest.audiocloud.io/" className="mb-20 link color-white">
              Speed Test
            </a>
          </div>
        </div>
        <div className="col-lg-2 col-sm-4 col-6 links" data-aos-duration="600" data-aos="fade-down" data-aos-delay="900">
          <div className="mb-25 f-18 medium title">
            <b>Social</b>
          </div>
          <SocialLinks1 color={"white"} />
          <SocialLinks2 color={"white"} />
        </div>
        <div className="mt-50 mt-sm-0 col-xl-2 col-lg-3 col-sm-4 col-7 text-center" data-aos-duration="600" data-aos="fade-down" data-aos-delay="1200">
          <a href="https://app.mixanalog.com" className="btn action-3 w-full px-0">
            Sign up
          </a>
          <div className="mt-20 pb-20">
            or{" "}
            <Link to="/#explainer" className="link color-white">
              Learn More
            </Link>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
